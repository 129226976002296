<script setup lang="ts">
import IconPresentation from '@/assets/icons/presentation.svg'

const props = defineProps({
  iconGray: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <BaseBadge>
    <IconPresentation :class="{ 'text-s-500': props.iconGray }" />
  </BaseBadge>
</template>
